<template>
    <div class="filter-container">
        <div class="filter-title">{{ title }}</div>
        <div class="filter-list">
            <div class="filter-list-elements">
                <div v-if="filterType == 'price'">
                    <div class="slider-container">
                        <div id="slider"></div>
                        <div class="price-inputs-container">
                            <div class="price-input-container">
                                <input class="price-input" type="number" id="price-min" name="price-min" min="0" max="10000"
                                    @change="onMinPriceChanged" />
                                <span class="price-description">Min</span>
                            </div>
                            <div class="price-input-container">
                                <input class="price-input" type="number" id="price-max" name="price-max" min="0" max="10000"
                                    @change="onMaxPriceChanged" />
                                <span class="price-description">Max</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-else v-for="(filterValue, index) in filterValues" :key="filterValue" class="filter-value">
                    <input class="filter-checkbox" type="checkbox" :id="filterValue['id'] + index"
                        :value="filterValue['id']" v-model="checkedFilters" @change="onFiltersChanged" o>
                    <label :for="filterValue['id'] + index" class="filter-label">{{ filterValue["description"] }}</label>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import noUiSlider from 'nouislider'
import 'nouislider/dist/nouislider.css'

let sliderCreated = false;
let slider;
let inputMin;
let inputMax;
let priceRange = [0, 3000];

export default {
    props: ['filter', 'cleanFilters'],
    data() {
        return {
            title: this.filter["title"],
            filterValues: this.filter["values"],
            filterType: this.filter["type"],
            checkedFilters: [],
            priceChanged: false,
        }
    },
    methods: {
        onFiltersChanged() {
            if (this.priceChanged) {
                this.$parent.onFiltersChanged(this.filterType, this.checkedFilters, priceRange)
            } else {
                this.$parent.onFiltersChanged(this.filterType, this.checkedFilters, null)
            }
        },

        onMinPriceChanged() {
            if (parseInt(inputMin.value) > parseInt(inputMax.value)) {
                inputMin.value = inputMax.value
            }
            this.onPriceChanged()
        },
        onMaxPriceChanged() {
            if (parseInt(inputMax.value) < parseInt(inputMin.value)) {
                inputMax.value = inputMin.value
            }
            this.onPriceChanged()
        },
        onPriceChanged() {
            priceRange[0] = inputMin.value;
            priceRange[1] = inputMax.value;
            slider.noUiSlider.set([inputMin.value, inputMax.value]);
            this.priceChanged = true
            this.$parent.onFiltersChanged(this.filterType, this.checkedFilters, priceRange)
        }
    },
    mounted() {
        if (sliderCreated == false && this.filterType == "price") {
            sliderCreated = true

            slider = document.getElementById('slider')
            inputMin = document.getElementById('price-min');
            inputMax = document.getElementById('price-max');

            if (slider != null) {
                noUiSlider.create(slider, {
                    start: [0, 3000],
                    connect: true,
                    range: {
                        'min': 0,
                        'max': 10000
                    }
                });

                slider.noUiSlider.on('update', function (values, handle) {
                    var value = values[handle];
                    if (handle == 0) {
                        inputMin.value = Math.round(value);
                    } else if (handle == 1) {
                        inputMax.value = Math.round(value);
                    }
                });

                slider.noUiSlider.on('end', function (values, handle) {
                    inputMin.value = Math.round(values[0]);
                    inputMax.value = Math.round(values[1]);
                    if (handle == 0) {
                        inputMin.dispatchEvent(new Event('change'));
                    } else if (handle == 1) {
                        inputMax.dispatchEvent(new Event('change'));
                    }
                });
            }
        }
    },
    unmounted() {
        sliderCreated = false
    }
}
</script>

<style>
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type=number] {
    -moz-appearance: textfield;
}

.price-inputs-container {
    margin-top: 1em;
    display: flex;
    justify-content: center;
    gap: 1em;
}

.price-input-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.price-description {
    font-weight: var(--fw-500);
    font-size: var(--fs-400);
    color: var(--black);
    margin-top: 5px;
}

.price-input {
    height: 30px;
    border: 1px solid var(--black);
    border-radius: 6px;
    text-align: center;
    font-weight: var(--fw-500);
    font-size: var(--fs-400);
    color: var(--black);
}

#slider {
    height: 10px;
    margin: 10px;
}

#slider .noUi-connect {
    background: var(--primary-color);
}

#slider .noUi-handle {
    height: 18px;
    width: 18px;
    top: -5px;
    right: -9px;
    border-radius: 9px;
    background: var(--primary-color);
    box-shadow: none;
}

#slider .noUi-handle:before,
.noUi-handle:after {
    content: none;
}

.filter-container {
    padding-top: 1.5em;
}

.filter-title {
    font-weight: var(--fw-600);
    font-size: var(--fs-400);
    color: var(--black)
}

.filter-list {
    border-radius: 6px 6px 6px 6px;
    border: 1px solid var(--secondary-grey);
    margin-top: 0.25em;
    padding-top: 0.75em;
    padding-bottom: 0.75em;
    padding-left: 1em;
    padding-right: 1em;
}

.filter-list-elements {
    max-height: 400px;
    overflow-x: hidden;
    overflow-y: scroll;
}

.filter-value {
    cursor: pointer;
    gap: 0.5em;
    display: flex;
}

.filter-label {
    font-weight: var(--fw-500);
    font-size: var(--fs-400);
    color: var(--primary-black);
    line-height: 25px;
}
</style>

